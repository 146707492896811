import styles from "./FirstSection.module.scss";
import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const FirstSection = () => {
  const [data, setData] = useState({});
  const [button, setButton] = useState({});
  const lang = useSelector((s) => s.reducer.lang);
  const domain = useSelector(s => s.reducer.domain);
  
  console.log(button);
  

  useEffect(() => {
    axios(`${domain}/${lang}/api/v1/base/banner/`)
      .then(({ data }) =>{
        data.length > 0
        ?  setData(data[0])
        :  setData({
            image: "",
            title: "",
            descriptions: "",
          });
      })
      .catch(() => {
        setData({
          image: "",
          title: "",
          descriptions: "",
        });
      });

      axios(`${domain}/${lang}/api/v1/base/keyboard/`)
      .then(({ data }) =>{
        data.length > 0
        ?  setButton(data[0])
        :  setButton({});
      })
      .catch(() => {
        setButton({});
      });
  }, [lang, domain]);


  return (
    <section className={styles.container}>
      <img src={data.image} alt="" />
      <div className={styles.text}>
        <div className={styles.discriptions}>
          <p>{data.title}</p>
        </div>
        <h1>{data.descriptions}</h1>
        {
          button && 
          <Link to={`/${lang}/registration`}><button className={styles.buttonLink}>{button.button}</button></Link>
          
        }
        
      </div>
    </section>
  );
};
