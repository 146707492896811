import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ErrorPage, ExhibitionPage, FundsPage, HomePage, ProjectsPage, BusinessPage, RegistrationPage } from "./pages";
import { Footer, Header, NavigationTop } from "./components";
// import { useEffect } from "react";
// import axios from 'axios'

function App() {
  // const getCsrfToken = () => {
  //   const start = document.cookie.indexOf('csrftoken=') + 10;
  //   const end = document.cookie.indexOf(';', start);
  //   if (end !== -1) {
  //     return document.cookie.slice(start, end)
  //   } else {
  //     return document.cookie.slice(start)
  //   }

  // };

  // useEffect(() => {
  //   axios.defaults.headers.post['X-CSRF-TOKEN'] = getCsrfToken();
  //   axios.defaults.headers.put['X-CSRF-TOKEN'] = getCsrfToken();
  //   axios.defaults.headers.patch['X-CSRF-TOKEN'] = getCsrfToken();
  //   axios.defaults.headers.delete['X-CSRF-TOKEN'] = getCsrfToken();


  // }, [])
  return (
    <BrowserRouter >
      <Header />
      <NavigationTop />

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/:lang" element={<HomePage />} />
        <Route path="/:lang/funds" element={<FundsPage />} />
        <Route path="/:lang/projects" element={<ProjectsPage />} />
        <Route path="/:lang/business" element={<BusinessPage />} />
        <Route path="/:lang/exhibition" element={<ExhibitionPage />} />
        <Route path="/:lang/registration" element={<RegistrationPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
