import './registrationPage.scss';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import './Modal/modal.scss';
export const RegistrationPage = () => {
    const lang = useSelector(s => s.reducer.lang);
    const domain = useSelector(s => s.reducer.domain);
    const [text, setText] = useState('');

    const [textStandart, setTextStandart] = useState('');
    const [textVip, setTextVip] = useState('');

    useEffect(() => {
        axios(`${domain}/${lang}/api/v1/registration/registration/`)
            .then(({ data }) => setText(data[0]));

        axios(`${domain}/${lang}/api/v1/registration/standart/`)
            .then(({ data }) => setTextStandart(data[0]));
        axios(`${domain}/${lang}/api/v1/registration/vip/`)
            .then(({ data }) => setTextVip(data[0]));
    }, [lang, domain]);

    const handleBooking = (url) => {
        window.location.href = url;
    };

    return (
        <div className='registration' style={{ minHeight: '100vh' }}>
            <div className="container">
                <h1 className="registration-title">{text.title}</h1>
                <p className="registration-text">{text.descriptions}</p>
                <div className="row">
                    <div className="col-6">
                        <div className="registration-widget">
                            <div className="registration-widget-text-bock">
                                <h4 className="registration-widget-title">{textStandart.title}</h4>
                                <div className="registration-widget-text" dangerouslySetInnerHTML={{ __html: textStandart.descriptions }}></div>
                            </div>
                            <button
                                className="registration-widget-btn"
                                onClick={() => handleBooking('https://bif-forum.airun.one/product-details/standart')}
                            >
                                Забронировать стандартный пакет
                            </button>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className="registration-widget">
                            <div className="registration-widget-text-bock">
                                <h4 className="registration-widget-title registration-widget-title-gold">{textVip.title}</h4>
                                <div className="registration-widget-text" dangerouslySetInnerHTML={{ __html: textVip.descriptions }}></div>
                            </div>
                            <button
                                className="registration-widget-btn"
                                onClick={() => handleBooking('https://bif-forum.airun.one/product-details/vip')}
                            >
                                Забронировать VIP пакет
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
