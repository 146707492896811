import { useState,useEffect } from 'react';
import styles from './ModalFinancing.module.scss';
import { useSelector } from 'react-redux';

export const ModalFinancing = ({setOpenModalForm ,openModal, setOpenModal, item}) => {

    const lang = useSelector(s =>s.reducer.lang)
    const [data,setData] = useState({})
    const domain = useSelector(s => s.reducer.domain);


    useEffect(()=>{
        (async()=>{
          const res =await fetch(`${domain}/${lang}/api/v1/founds/nameinfo/${item.id}/`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
          })
          const data_res =await res.json()

          setData(data_res);

        })()
    },[lang, domain, item])


    if(!openModal){
        return <></>;
    }
    return (
        <div onClick={() => {setOpenModal(false); }} className={styles.open}>

             <div className={styles.open_wrap}>

                <div onClick={event => event.stopPropagation()} className={styles.descriptionBlock}>
                    <div className={styles.img}>
                        <img src={data.image} alt="logo" />
                    </div>
                    <div className={styles.text}>

                        <h2>{
                            lang === 'ru'
                            ? 'Название'
                            : lang === 'en'
                            ? 'Name'
                            : 'Аталышы'
                    }</h2>
                        <p>{data.title}</p>

                        <h2>{
                            lang === 'ru'
                            ? 'Описание'
                            : lang === 'en'
                            ? 'Description'
                            : 'Сүрөттөмө'
                            }</h2>
                        <p>{data.descriptions}</p>

                        <h2>{
                            lang === 'ru'
                            ? 'Виды финансирования'
                            : lang === 'en'
                            ? 'Types of financing'
                            : 'Каржылоонун түрлөрү'
                            }</h2>
                        <p>{data.type_financing}</p>
                    </div>
                </div>

        <div className={styles.button}>
            <button onClick={() => setOpenModalForm(true)} className={styles.callButton}>
                {
                    lang === 'ru'
                    ? 'Связаться'
                    : lang === 'en'
                    ? 'Contact'
                    : 'Байланышуу'
                }
            </button>
        </div>
    </div>
    </div>


    );

}
