import { useEffect, useState } from "react";
import styles from "./ModalForm.module.scss";
import { ConfirmModal } from "@components/index";
import { useSelector } from "react-redux";
import axios from "axios";

export const ModalForm = ({ openModalForm, setOpenModalForm, setComplate }) => {
  const [confirm,setConfirm] = useState(false);
  const [fondsList, setFondsList] = useState([]);
  const [legalNamesList, setLegalNamesList] = useState([]);
  const [sectorsList, setSectorsList] = useState([]);
  const [selectedSector, setSelectedSector] = useState(-1);

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  }

  const [sendData, setSendData] = useState({
    full_name: "",
    name_company: "",
    legal_name: "",
    brief_description: "",
    sector: "",
    fond: "",
    contact: "",
    email: "",
  });


  const [data, setData]= useState({})
  const domain = useSelector(s => s.reducer.domain);
  const lang = useSelector(s => s.reducer.lang);



  useEffect(()=>{
    (async()=>{
      try{
        const res1 = await fetch(`${domain}/${lang}/api/v1/founds/legal-names/`)
        const res_data1 =await res1.json()
        const res2 = await fetch(`${domain}/${lang}/api/v1/founds/sectors/`)
        const res_data2 =await res2.json()
        setData({...data,legal_names:res_data1,sectors:res_data2})
      }catch(err){
        console.error(err);
      }
    })()
    axios(`${domain}/${lang}/api/v1/founds/fond/`)
    .then(({data})=> setFondsList(data));

    axios(`${domain}/${lang}/api/v1/founds/legal-names/`)
    .then(({data})=> setLegalNamesList(data));

    axios(`${domain}/${lang}/api/v1/founds/sectors/`)
    .then(({data})=> setSectorsList(data));

  },[domain, lang, data])





  const changeSendData = (key, value) => {
    setSendData({
      ...sendData,
      [key]: value
    });

  };



  const submitForm = async (event) => {
    event.stopPropagation();
    console.log(sendData);

    const check =
      sendData.full_name &&
      sendData.name_company &&
      sendData.legal_name &&
      sendData.brief_description &&
      sendData.sector &&
      sendData.fond &&
      sendData.email &&
      sendData.contact;
    if (!check) {
      alert("Вы не заполнили все поля !");
      return;
    }
    const getCsrfToken = () => {
      const start = document.cookie.indexOf('csrftoken=') + 10;
      const end = document.cookie.indexOf(';', start);
      if (end !== -1) {
        return document.cookie.slice(start, end)
      } else {
        return document.cookie.slice(start)
      }

    };

      axios.post(`${domain}/${lang}/api/v1/founds/reach/`, sendData,  {
        headers: {
          'X-CSRFToken': getCsrfToken()
        }
      })
      .then((data)=>{
        setConfirm(true)
        console.log(data);
      })
      .catch(error=>{
        console.log(error);
        alert("Что-то пошло не так(");
      })


  };

  const [focusedInput, setFocusedInput] = useState({});

  if (!openModalForm) {
    return null;
  }

  const handleFocus = (inputId) => {
    setFocusedInput((prevState) => ({
      ...prevState,
      [inputId]: true,
    }));
  };

  const handleBlur = (inputId) => {
    setFocusedInput((prevState) => ({
      ...prevState,
      [inputId]: false,
    }));
  };

  return (
    <div
      onClick={() => setOpenModalForm(!openModalForm)}
      className={styles.open}
    >
      {
        confirm?
        <ConfirmModal setConfirm={setConfirm}/>
        :''
      }
      <div className={styles.open_wrap}>
      <div
        onClick={(event) => event.stopPropagation()}
        className={styles.block}
      >
        <div className={styles.container}>
          <form  className={styles.form}>
            <input
              onFocus={() => handleFocus('input1')}
              onBlur={() => handleBlur('input1')}
              className={focusedInput['input1'] ? styles.inputFocused : ''}
              type="text"
              placeholder={
                lang === 'ru'
                ? 'ФИО'
                : lang === 'en'
                ? 'FULL NAME'
                : 'А.Ж.'
              }
              onChange={(e) => changeSendData( "full_name", e.target.value)}
            />
            <input
              type="text"
              onFocus={() => handleFocus('input2')}
              onBlur={() => handleBlur('input2')}
              className={focusedInput['input2'] ? styles.inputFocused : ''}
              placeholder={
                lang === 'ru'
                ? "Название вашей компании"
                : lang === 'en'
                ? 'Name of your company'
                : 'Сиздин компаниянын аталышы'
              }
              onChange={(e) => changeSendData("name_company", e.target.value, )}
            />
            <input
              type="textArea"
              onFocus={() => handleFocus('input3')}
              onBlur={() => handleBlur('input3')}
              className={focusedInput['input3'] ? styles.inputFocused : ''}
              placeholder={
                lang === 'ru'
                ? "Краткое описание деятельности вашей компании"
                : lang === 'en'
                ? "A brief description of your company's activities"
                : 'Сиздин компанияңыздын ишинин кыскача баяндамасы'
              }
              onChange={(e) =>
                changeSendData( "brief_description", e.target.value,)
              }
            />

              <select
                name=""
                id=""
                onFocus={() => handleFocus('select1')}
                onBlur={() => handleBlur('select1')}
                className={`${styles.select} ${focusedInput['select1'] ? styles.selectFocus : ''}`}

               onChange={(e) => changeSendData("legal_name", e.target.value, )}
              >
                <option value="0">{
                  lang === 'ru'
                  ? "Юридическое название"
                  : lang === 'en'
                  ? 'Legal name'
                  : 'Юридикалык аталышы'
                  }</option>
                  {
                    legalNamesList.map(item =>{
                      return <option value={item.name} key={item.id}>{item.name}</option>
                    })
                  }
              </select>
              <select name="" id=""
              onFocus={() => handleFocus('select2')}
                onBlur={() => handleBlur('select2')}
                className={`${styles.select} ${focusedInput['select2'] ? styles.selectFocus : ''}`}
                onChange={(e) => {
                  changeSendData("sector", e.target.value)
                  setSelectedSector(e.target.value)
                }}
                defaultValue={'0'}
              >
              <option value="0" disabled>{
                  lang === 'ru'
                  ? "Укажите сектор"
                  : lang === 'en'
                  ? 'Specify sector'
                  : 'Секторду көрсөтүңүз'
                  }</option>
                  {
                    sectorsList.map((item, idx) =>{
                      return <option value={
                        idx == sectorsList.length
                        ? idx
                        : item.title
                      } key={item.id}>{item.title}</option>
                    })
                  }
              </select>

                  {
                    selectedSector == sectorsList.length
                    ? <input
                    onFocus={() => handleFocus('input3')}
                    onBlur={() => handleBlur('input3')}
                    className={focusedInput['input3'] ? styles.inputFocused : ''}
                          autoFocus={true}
                          type="text"
                          placeholder={
                            lang === 'ru'
                              ?  "Опишите"
                              : lang === 'en'
                              ?   "Describe"
                              :   "Сүрөттөө"
                          }
                          onChange={(e)=>{
                            changeSendData("sector", e.target.value)
                          }}
                    />
                    : ''
                  }


              <select name="" id=""  
                onFocus={() => handleFocus('select3')}
                onBlur={() => handleBlur('select3')}
                className={`${styles.select} ${focusedInput['select3'] ? styles.selectFocus : ''}`}
              onChange={(e) => changeSendData("fond", e.target.value)}
              >
              <option value="0" >{
                  lang === 'ru'
                  ? "Какой фонд интересует"
                  : lang === 'en'
                  ? 'Which fund are you interested in?'
                  : 'Сиз кайсы фондго кызыгасыз?'
                  }</option>

                  {
                    fondsList.map(item =>{
                      return <option value={item.name} key={item.id}>{item.name}</option>
                    })
                  }
              </select>

            <input
              type="tel"
              onFocus={() => handleFocus('input4')}
              onBlur={() => handleBlur('input4')}
              className={focusedInput['input4'] ? styles.inputFocused : ''}
              placeholder={
                lang === 'ru'
                ? 'Ваши контакты'
                : lang === 'en'
                ? 'Your contacts'
                : 'Сиздин байланыштарыңыз'
              }
              onChange={(e) => changeSendData( "contact", e.target.value)}
            />

            <input
            style={
            {
              border: sendData.email && !validateEmail(sendData.email) ? '1px solid red' : ''
            }
          }
              onFocus={() => handleFocus('input5')}
              onBlur={() => handleBlur('input5')}
              className={focusedInput['input5'] ? styles.inputFocused : ''}
              type="email"
              placeholder={
                lang === 'ru'
                ? 'Ваша e-mail почта'
                : lang === 'en'
                ? 'Your email'
                : 'Сиздин электрондук почтаңыз'
              }
              onChange={(e) => changeSendData( "email", e.target.value)}
            />
          </form>
        </div>

      </div>
      <div className={styles.button}>
        <button onClick={submitForm} className={styles.callButton}>
        {
                    lang === 'ru'
                    ? 'Связаться'
                    : lang === 'en'
                    ? 'Contact'
                    : 'Байланышуу'
                }
        </button>
      </div>
      </div>

    </div>
  );
};
